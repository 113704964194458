@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&family=Heebo:wght@100;200;300;400;500;600;800;900&display=swap');

$themeBlue: #5064DE;
$themeYollow: #FFE8B4;
$themeFont: 'Heebo';
$themeFont2: 'Assistant';
$clear: 0 0 0 0;

* {
    box-sizing: border-box;
    text-decoration: none !important;
}

@mixin importantWl {
    width: 100%;
    float: left;
}

.formFildInpt {
    font-family: $themeFont !important;
}

body {
    @include importantWl();
    margin: $clear;
    padding: $clear;
    font-family: $themeFont;
}

header {
    @include importantWl();
    padding: 28px 48px;
    background-color: $themeBlue;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    a.logoWrap {
        float: left;
    }
    .rightBttns {
        float: left;
        display: flex;
        align-items: center;
        gap: 45px;
        flex-flow: row wrap;
        a.btnStyle {
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            color: #5064DE;
            background-color: #fff;
            padding: 7px 13px;
            border-radius: 33px;
        }
        a.linkStyle {
            font-style: normal;
            font-weight: 400;
            font-size: 25px;
            color: #FFFFFF;
        }
    }
}

.dashboardWrap {
    @include importantWl();
    margin: 70px 0 0 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    .dbContainer {
        width: 895px;
        float: left;
        .dbinner {
            @include importantWl();
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            picture {
                @include importantWl();
                text-align: center;
            }
            h1 {
                @include importantWl();
                text-align: center;
                font-style: normal;
                font-weight: 500;
                font-size: 32px;
                color: #375177;
                margin: 0 0 40px 0;
                small {
                    max-width: 480px;
                    display: inline-block;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 21px;
                    line-height: 39px;
                    text-align: center;
                    color: #39546A;
                    margin: 20px 0 0 0;
                }
            }
            h3 {
                @include importantWl();
                text-align: center;
                font-style: normal;
                font-weight: 700;
                font-size: 21px;
                line-height: 31px;
                color: #39546A;
                margin: 5px 0;
            }
            p {
                @include importantWl();
                margin: 45px 0 0 0;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 25px;
                text-align: center;
                color: #39546A;
            }
            a.blueBttnStyle {
                background: $themeBlue;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.239019);
                border-radius: 28px;
                padding: 15px 52px;
                font-style: normal;
                font-weight: 400;
                font-size: 25px;
                line-height: 16px;
                color: #FFFFFF;
                margin: 66px 0 0 0;
            }
            .linkStyle {
                font-style: normal;
                font-weight: 400;
                font-size: 25px;
                line-height: 29px;
                margin: 26px 0 50px 0;
                color: #8C93BB;
            }
        }
    }
}

.completeRiskPageWrap {
    @include importantWl();
    padding: 103px 0 50px 0;
    display: flex;
    align-items: center;
    flex-flow: column;
    background-color: #f8f8ff;
    min-height: calc(100vh - 117px);
    .completeRiskPageContainer {
        width: 722px;
        float: left;
        .rpinner {
            @include importantWl();
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            .rpTopBox {
                @include importantWl();
                background: #FFFFFF;
                box-shadow: 0px 8px 24px rgba(1, 33, 85, 0.06);
                border-radius: 16px;
                min-height: 280px;
                padding: 32px 0;
                display: flex;
                align-items: center;
                flex-flow: column;
                picture {
                    @include importantWl();
                    text-align: center;
                }
                h2 {
                    @include importantWl();
                    text-align: center;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 21px;
                    line-height: 23px;
                    color: #375177;
                    margin: 5px 0 0 0;
                }
                p {
                    @include importantWl();
                    text-align: center;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 24px;
                    color: #4E4B66;
                    margin: 20px 0 0 0;
                    font-family: $themeFont2;
                }
            }
            .noticeWrap {
                width: 440px;
                padding: 18px 15px;
                background-color: #FFE8B4;
                border-radius: 9px 20px 9px 20px;
                display: flex;
                gap: 8px;
                align-items: center;
                margin: 40px 0 0 0;
                img {
                    width: 18px;
                    height: 18px;
                    float: left;
                    object-fit: cover;
                }
                p {
                    float: left;
                    margin: 0 0;
                    font-family: $themeFont2;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 24px;
                    color: #4E4B66;
                }
                a {
                    color: #4E4B66;
                    text-decoration: underline !important;
                }                
            }
            .sliderCompleteWrap {
                @include importantWl();
                margin: 40px 0 0 0;
                display: flex;
                flex-flow: column;
                align-items: center;
                h1 {
                    @include importantWl();
                    font-style: normal;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 23px;
                    color: #375177;
                    text-align: center;
                    margin: $clear;
                    small {
                        @include importantWl();
                        font-style: normal;
                        font-weight: 400;
                        font-size: 21px;
                        line-height: 23px;
                        text-align: center;
                        color: #375177;
                        margin: 17px 0 0 0;
                    }
                }
                .reactSlider {
                    @include importantWl();
                    margin: 100px 0 0 0;
                    .horizontal-slider {
                        @include importantWl();
                        height: 50px;
                        .example-thumb {
                            top: -5px;
                            width: 49px;
                            outline: none;
                            height: 49px;
                            cursor: pointer;
                            position: absolute;
                            z-index: 100;
                            background: #1070C8;
                            border: 2px solid #FFFFFF;
                            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
                            border-radius: 100%;
                            display: block;
                            line-height: 76px;
                            font-size: 11px;
                            font-weight: 500;
                            text-align: center;
                            span.thumbnail {
                                background-color: #fff;
                            }
                        }
                        .example-track {
                            position: relative;
                            background: #EDEEF1;
                        }
                        .example-track.example-track-1 {
                            background: #EDEEF1;
                        }
                          .example-track-2{
                            background: #EDEEF1;
                        }
                        .example-track {
                            top: 14px;
                            height: 13px;
                            border-radius: 20px;
                        }
                        .example-mark {
                            width: 9px;
                            height: 9px;
                            background-color: #1070C8;
                            border-radius: 100%;
                            margin: 0 0 0 17px;
                            top: 16px;
                            opacity: 0.4;
                        }
                    }
                    .slideMarks {
                        @include importantWl();
                        display: flex;
                        margin: 8px 0 0 0;
                        justify-content: space-between;
                        flex-flow: row-reverse;
                        p {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 18px;
                            color: #012155;
                            margin: 0 0;
                        }
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                        input[type=number] {
                            appearance: textfield;
                        }
                        .inputNumberType {
                            width: 130px;
                            height: 50px;
                            float: left;
                            padding: 0 0;
                            background: #fff;
                            box-shadow: 0px 8px 24px rgba(1, 33, 85, 0.15);
                            border-radius: 12px;
                            color: #3D69FF;
                            font-weight: 700;
                            font-size: 16px;
                            float: left;
                            border: 0;
                            text-align: center;
                            outline: none;
                        }
                    }
                }   
            }          
            a.bluebttnStyle {
                background: $themeBlue;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.239019);
                border-radius: 28px;
                padding: 15px 52px;
                font-style: normal;
                font-weight: 400;
                font-size: 25px;
                line-height: 16px;
                color: #FFFFFF;
                margin: 120px 0 0 0;
            }
        }   
    }
}
.investmentTotalWrap {
    @include importantWl();
    min-height: calc(100vh - 117px);
    background-color: #f8f8ff;
    display: flex;
    justify-content: center;
    .ipContainer {
        width: 820px;
        float: left;
        .ipInner {
            @include importantWl();
            margin: 102px 0 50px 0;
            display: flex;
            flex-flow: column;
            align-items: center;
            h1 {
                @include importantWl();
                font-style: normal;
                font-weight: 500;
                font-size: 32px;
                line-height: 23px;
                text-align: center;
                color: #375177;
                margin: $clear;
            }
            .depositAmmount {
                @include importantWl();
                background-color: #fff;
                margin: 50px 0 0 0;
                padding: 32px 0;
                box-shadow: 0px 8px 24px rgba(1, 33, 85, 0.06);
                border-radius: 16px;
                h2 {
                    @include importantWl();
                    text-align: center;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 23px;
                    color: #375177;
                    margin: $clear;
                    small {
                        @include importantWl();
                        margin: 0 0 20px 0;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 21px;
                        line-height: 18px;
                        color: #012155;
                    }
                }
                p {
                    @include importantWl();
                    font-style: normal;
                    font-weight: 300;
                    font-size: 21px;
                    line-height: 24px;
                    text-align: center;
                    color: #012155;
                    margin: 8px 0 0 0;
                }
            }
            .pieChart {
                width: 337px;
                float: left;
                margin: 105px 0 100px 0;
                border-radius: 100%;
                background-color: #fff;
                box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
                padding: 16px 16px 10px 16px;
                position: relative;
                svg path {
                    border-radius: 30px;
                }
                .label_one {
                    position: absolute;
                    top: -51px;
                    right: -110px;
                    h4 {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 19px;
                        text-align: right;
                        letter-spacing: -0.5px;
                        color: #333333;
                        margin: 0;
                        display: flex;
                        flex-flow: column;
                        small {
                            @include importantWl();
                            font-style: normal;
                            font-weight: 400;
                            font-size: 15px;
                            line-height: 23px;
                            color: #333333;
                        }
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        right: 40px;
                        top: 74px;
                        width: 136px;
                        height: 23px;
                        background-image: url(../images/line_01.svg);
                        background-size: cover;
                        background-repeat: no-repeat;
                    }
                }
                .label_two {
                    position: absolute;
                    top: -51px;
                    left: -110px;
                    h4 {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 19px;
                        text-align: right;
                        letter-spacing: -0.5px;
                        color: #333333;
                        margin: 0;
                        display: flex;
                        flex-flow: column;
                        small {
                            @include importantWl();
                            font-style: normal;
                            font-weight: 400;
                            font-size: 15px;
                            line-height: 23px;
                            color: #333333;
                        }
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        left: 40px;
                        top: 74px;
                        width: 136px;
                        height: 23px;
                        background-image: url(../images/line_02.svg);
                        background-size: cover;
                        background-repeat: no-repeat;
                    }
                }
                
                .label_three {
                    position: absolute;
                    bottom: -51px;
                    right: -110px;
                    h4 {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 19px;
                        text-align: right;
                        letter-spacing: -0.5px;
                        color: #333333;
                        margin: 0;
                        display: flex;
                        flex-flow: column;
                        small {
                            @include importantWl();
                            font-style: normal;
                            font-weight: 400;
                            font-size: 15px;
                            line-height: 23px;
                            color: #333333;
                        }
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        right: 40px;
                        top: -49px;
                        width: 136px;
                        height: 23px;
                        background-image: url(../images/line_01.svg);
                        background-size: cover;
                        background-repeat: no-repeat;
                        transform: rotateX(180deg);
                    }
                }
                .label_four {
                    position: absolute;
                    bottom: -51px;
                    left: -110px;
                    h4 {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 19px;
                        text-align: right;
                        letter-spacing: -0.5px;
                        color: #333333;
                        margin: 0;
                        display: flex;
                        flex-flow: column;
                        small {
                            @include importantWl();
                            font-style: normal;
                            font-weight: 400;
                            font-size: 15px;
                            line-height: 23px;
                            color: #333333;
                        }
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        left: 40px;
                        top: -49px;
                        width: 136px;
                        height: 23px;
                        background-image: url(../images/line_02.svg);
                        background-size: cover;
                        background-repeat: no-repeat;
                        transform: rotateX(180deg);
                    }
                }
            }
            .ramgeSliderWrap {
                width: 670px;
                float: left;
                h2 {
                    @include importantWl();
                    text-align: center;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 32px;
                    line-height: 23px;
                    color: #333333;
                    margin: 0 0;
                }
                .rSlid {
                    @include importantWl();
                    position: relative;
                    margin: 100px 0 0 0;
                    display: flex;
                    flex-flow: column;
                    align-items: center;
                    .horizontal-slider {
                        @include importantWl();
                        height: 50px;
                        .example-thumb {
                            top: -5px;
                            width: 49px;
                            outline: none;
                            height: 49px;
                            cursor: pointer;
                            position: absolute;
                            z-index: 100;
                            background: #1070C8;
                            border: 2px solid #FFFFFF;
                            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
                            border-radius: 100%;
                            display: block;
                            line-height: 76px;
                            font-weight: 500;
                            text-align: center;
                            transition: 0.3s ease-in-out;
                            span {
                                position: absolute;
                                background-color: #fff;
                            }
                        }
                        .example-track {
                            position: relative;
                            background: #EDEEF1;
                        }
                        .example-track.example-track-1 {
                            background: #EDEEF1;
                        }
                          .example-track-2{
                            background: #EDEEF1;
                        }
                        .example-track {
                            top: 14px;
                            height: 13px;
                            border-radius: 20px;
                        }
                        .example-mark {
                            width: 16px;
                            height: 16px;
                            background-color: #1070C8;
                            border-radius: 100%;
                            margin: 0 0 0 17px;
                            top: 12px;
                            opacity: 0.4;
                        }
                    }
                    .arealabel {
                        @include importantWl();
                        display: flex;
                        justify-content: space-between;
                        flex-flow: row-reverse;
                        margin: 15px 0 0 0;
                        p {
                            max-width: 50px;
                            float: left;
                            text-align: center;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 15px;
                            color: #012155;
                            margin: 0 0;
                        }
                    }
                    .riskLevelLebel {
                        width: 299px;
                        height: 56px;
                        display: flex;
                        flex-flow: column;
                        align-items: center;
                        justify-content: center;
                        background: #FFFFFF;
                        border: 2px solid #FFE8B4;
                        border-radius: 8px 18px;
                        font-family: $themeFont2;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 24px;
                        color: #4E4B66;
                        margin: 44px 0 0 0;
                        float: left;
                        position: relative;
                        padding: 0 0 13px 0;
                        outline: none !important;
                        cursor: pointer;
                        &::after {
                            content: '';
                            position: absolute;
                            width: 30px;
                            height: 2px;
                            background-color: #ECECEC;
                            bottom: 13px;
                        }
                    }
                }
            }

            .riskLevelCard {
                @include importantWl();
                margin: 30px 0 30px 0;
                min-height: 275px;
                background: #FFFFFF;
                box-shadow: 0px 8px 24px rgba(1, 33, 85, 0.06);
                border-radius: 16px;
                display: flex;
                flex-flow: column;
                align-items: center;
                justify-content: center;
                img {
                    width: 101px;
                }
                h4 {
                    width: 255px;
                    float: left;
                    margin: 10px 0;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 21px;
                    line-height: 23px;
                    text-align: center;
                    color: #375177;
                }
                p {
                    max-width: 620px;
                    float: left;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 24px;
                    text-align: center;
                    color: #4E4B66;
                    font-family: $themeFont2;
                    margin: $clear;
                }
            }
            .confirmationWrap {
                width: 670px;
                float: left;
                margin: 30px 0 0px 0;
                p {
                    @include importantWl();
                    margin: 0 0 23px 0;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 21px;
                    line-height: 23px;
                    color: #375177;
                }
                .radioBox {
                    @include importantWl();
                    label {
                        float: right;
                        display: flex;
                        align-items: center;
                        gap: 12px;
                        margin: 0 0 0 35px;
                        cursor: pointer;
                        span {
                            width: 48px;
                            height: 48px;
                            border: 2px solid $themeBlue;
                            float: right;
                            border-radius: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            b.mark {
                                width: 28px;
                                height: 28px;
                                background-color: $themeBlue;
                                float: left;
                                border-radius: 100%;
                                transition: 0.3s ease-in-out;
                                transform: scale(0);
                            }
                        }
                        input[type="radio"] {
                            display: none;
                            &:checked ~ span b {
                                transform: scale(1);
                            }
                        }
                        h5 {
                            float: left;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 18px;
                            margin: $clear;
                            color: #333333;
                        }
                    }
                }
            }
            .subBttns {
                @include importantWl();
                margin: 50px 0 0px 0;
                display: flex;
                flex-flow: column;
                align-items: center;
                .subBttn {
                    background: $themeBlue;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.239019);
                    border-radius: 28px;
                    padding: 15px 52px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 25px;
                    line-height: 16px;
                    color: #FFFFFF;
                    margin: 0px 0 0 0;
                    border: 0;
                    outline: none !important;
                    cursor: pointer !important;
                    font-family: $themeFont;
                }
                .backbttn {
                    background-color: transparent;
                    border: 0;
                    outline: none !important;
                    cursor: pointer !important;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 25px;
                    line-height: 29px;
                    color: #8C93BB;
                    margin: 23px 0 0 0;
                    font-family: $themeFont;
                }
            }
        }
    }
}

.riskPopWrap {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 13, 38, 0.3);
    backdrop-filter: blur(3px);
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    .rPopMain {
        width: 796px;
        min-height: 653px;
        background-color: #fff;
        border-radius: 8px;
        background-image: url(../images/popBac.png);
        background-size: cover;
        background-repeat: no-repeat;
        padding: 100px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        position: relative;
        .removePop {
            position: absolute;
            top: 18px;
            left: 23px;
            font-size: 25px;
            color: #017DC5;
            cursor: pointer;
        }
        h4 {
            @include importantWl();
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 47px;
            text-align: center;
            color: #4E4B66;
            margin: 12px 0 0 0;
        }
        .rSlid {
            @include importantWl();
            position: relative;
            margin: 80px 0 0 0;
            display: flex;
            flex-flow: column;
            align-items: center;
            .horizontal-slider {
                @include importantWl();
                height: 50px;
                .example-thumb {
                    top: -5px;
                    width: 49px;
                    outline: none;
                    height: 49px;
                    cursor: pointer;
                    position: absolute;
                    z-index: 100;
                    background: #1070C8;
                    border: 2px solid #FFFFFF;
                    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
                    border-radius: 100%;
                    display: block;
                    line-height: 76px;
                    font-weight: 500;
                    text-align: center;
                    transition: 0.3s ease-in-out;
                    // font-size: 0;
                }
                .example-track {
                    position: relative;
                    background: #EDEEF1;
                }
                .example-track.example-track-1 {
                    background: #EDEEF1;
                }
                  .example-track-2{
                    background: #EDEEF1;
                }
                .example-track {
                    top: 14px;
                    height: 13px;
                    border-radius: 20px;
                }
                .example-mark {
                    width: 16px;
                    height: 16px;
                    background-color: #1070C8;
                    border-radius: 100%;
                    margin: 0 0 0 17px;
                    top: 12px;
                    opacity: 0.4;
                }
            }
            .arealabel {
                @include importantWl();
                display: flex;
                justify-content: space-between;
                flex-flow: row-reverse;
                margin: 15px 0 0 0;
                p {
                    max-width: 50px;
                    float: left;
                    text-align: center;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 15px;
                    color: #012155;
                    margin: 0 0;
                }
            }
        }        
        .subBttn {
            background: $themeBlue;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.239019);
            border-radius: 35px;
            padding: 11px 62px 20px 62px;
            font-style: normal;
            font-weight: 400;
            font-size: 25px;
            color: #FFFFFF;
            margin: 60px 0 0 0;
            border: 0;
            outline: none !important;
            cursor: pointer !important;
            font-family: $themeFont !important;
        }
    }
}

.completeFormBlock {
    @include importantWl();
    display: flex;
    flex-flow: column;
    align-items: center;
    .formInner {
        width: 785px;
        float: left;
        margin: 40px 0 0 0;
        display: flex;
        flex-flow: column;
        align-items: center;
        .countBar {
            width: 390px;
            float: left;
            display: flex;
            justify-content: space-between;
            flex-flow: row-reverse;
            align-items: center;
            position: relative;
            margin: 0 0 60px 0;
            button {
                width: 31px;
                height: 31px;
                float: left;
                border-radius: 100%;
                border: 4px solid rgba(170, 179, 235);
                background-color: #fff;
                position: relative;
                z-index: 1;
                &.active {                    
                    cursor: default;
                    &::after{
                        content: '';
                        position: absolute;
                        width: 13px;
                        height: 13px;
                        border-radius: 100%;
                        background-color: $themeBlue;
                        top: 5px;
                        left: 5px;
                    }
                }
            }
            &:after {
                width: 100%;
                content: "";
                position: absolute;
                border-top: 3px dashed rgb(170, 179, 235);
                top: 14px;
                left: 0;            
            }
        }
        h2 {
            @include importantWl();
            text-align: center;
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 23px;
            color: #375177;
            margin: 20px 0 0 0;
        }
        .mainForm {
            @include importantWl();
            margin: 55px 0 0 0;
            .formFildDouble {
                @include importantWl();
                display: flex;
                gap: 24px;
                flex-flow: row wrap;
                margin: 0 0 10px 0;
                .formFildInpt {
                    width: calc(50% - 12px);
                    float: left;
                    background: #F7F8FD;
                    border-radius: 10px;
                    border: 1px solid #F7F8FD;
                    height: 70px;
                    outline: none !important;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 26px;
                    color: #8C93BB;
                    padding: 0 40px;
                    color: #8C93BB;      
                    &:focus {
                        border: 1px solid #5064de !important;
                        background-color: rgba(80, 100, 222, 0.16);
                    }
                    &::placeholder {
                        color: #8C93BB;
                    }         
                    &:focus {
                        border: 1px solid #5064de !important;
                        background-color: rgba(80, 100, 222, 0.16) !important;
                    }
                }
            }

            .formFildSingles {
                @include importantWl();
                margin: 30px 0 0 0;
                &.smallMargin {
                    margin: $clear;
                }
                .formFildInpt {
                    width: 100%;
                    float: left;
                    background: #F7F8FD;
                    border-radius: 10px;
                    border: 1px solid #F7F8FD;
                    height: 70px;
                    outline: none !important;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 26px;
                    color: #8C93BB;
                    padding: 0 40px;
                    color: #8C93BB;
                    margin: 10px 0;
                    &::placeholder {
                        color: #8C93BB;
                    }      
                    &:focus {
                        border: 1px solid #5064de !important;
                        background-color: rgba(80, 100, 222, 0.16);
                    }
                }
                select.formFildInpt {
                    appearance: none !important;
                    background-image: url(../images/selectArrow.svg);
                    background-repeat: no-repeat;                    
                    background-position: 40px 50%;
                }
            }
            .completeRadioWrap {
                @include importantWl();
                margin: 30px 0 20px 0;
                p {
                    @include importantWl();
                    margin: 0 0 23px 0;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 21px;
                    line-height: 23px;
                    color: #375177;
                }
                .radioBox {
                    @include importantWl();
                    label {
                        float: right;
                        display: flex;
                        align-items: center;
                        gap: 12px;
                        margin: 0 0 0 35px;
                        cursor: pointer;
                        span {
                            width: 48px;
                            height: 48px;
                            border: 2px solid $themeBlue;
                            float: right;
                            border-radius: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            b.mark {
                                width: 28px;
                                height: 28px;
                                background-color: $themeBlue;
                                float: left;
                                border-radius: 100%;
                                transition: 0.3s ease-in-out;
                                transform: scale(0);
                            }
                        }
                        input[type="radio"] {
                            display: none;
                            &:checked ~ span b {
                                transform: scale(1);
                            }
                        }
                        h5 {
                            float: left;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 18px;
                            margin: $clear;
                            color: #333333;
                        }
                    }
                }
            }
            .confirmCheckBoxWrap {
                @include importantWl();
                margin: 30px 0 20px 0;
                position: relative;
                &.addOverlay::after {
                    content: "";
                    width: 100%;
                    height: 60px;
                    background-color: rgba(255, 255, 255, 0.7);
                    position: absolute;
                    z-index: 99;
                    top: 111px;
                    left: 0;
                }
                label {
                    @include importantWl();
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    position: relative;
                    p {
                        float: left;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 23px;
                        color: #8E95BC;
                        margin: $clear
                    }
                    span {
                        float: left;
                        min-width: 48px;
                        max-width: 48px;
                        height: 48px;
                        background: #F7F8FD;
                        border: 2px solid $themeBlue;
                        border-radius: 6px;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        svg {
                            color: $themeBlue;
                            font-size: 30px;
                            transform-origin: bottom left;
                            transform: scale(0);
                            transition: 0.3s ease-in-out;
                        }
                    }
                    .chackBox {
                        display: none;
                        &:checked ~ span svg {
                            transform: scale(1);
                        }
                    }
                }
            }
            .spouceInfo {
                @include importantWl();
                h2 {
                    margin: 20px 0 40px 0;
                }
            }
            .smallHalfWrapper {
                @include importantWl();
                display: flex;
                justify-content: space-between;
                margin: 10px 0;
                .shBlocks {
                    width: calc(100% - 220px);
                    float: left;
                    &.small {
                        width: 175px;
                    }
                    span {
                        @include importantWl();
                        font-style: normal;
                        font-weight: 400;
                        font-size: 21px;
                        line-height: 23px;
                        color: #375177;
                        margin: 0 0 8px 0;
                    }
                    .formFildInpt {
                        width: 100%;
                        float: left;
                        background: #F7F8FD;
                        border-radius: 10px;
                        border: 1px solid #F7F8FD;
                        height: 70px;
                        outline: none !important;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 26px;
                        color: #8C93BB;
                        padding: 0 40px;
                        margin: 10px 0;
                        &::placeholder {
                            color: #8C93BB;
                        }      
                        &:focus {
                            border: 1px solid #5064de !important;
                            background-color: rgba(80, 100, 222, 0.16);
                        }
                    }
                    select.formFildInpt {
                        appearance: none !important;
                        background-image: url(../images/selectArrow.svg);
                        background-repeat: no-repeat;                    
                        background-position: 40px 50%;
                    }
                }
            }
            .uploadTypeFormFeald {
                @include importantWl();
                margin: 20px 0 0 0;
                .fealdType {
                    @include importantWl();
                    background: #F7F8FD;
                    border-radius: 10px;
                    border: 1px solid #F7F8FD;
                    height: 70px;
                    outline: none !important;
                    font-style: normal;
                    font-size: 18px;
                    line-height: 26px;
                    color: #8C93BB;
                    padding: 0 40px;
                    margin: 10px 0;
                    cursor: pointer;
                    background-image: url(../images/uploadicon.svg);
                    background-repeat: no-repeat;
                    background-position: 30px 50%;
                    position: relative;
                    b {
                        font-weight: 400;
                        top: 18px;
                        right: 40px;
                        position: absolute;
                        width: 70%;
                        background: #F7F8FD;
                        &::after {
                            content: "";
                            position: absolute;
                            height: 100%;
                            width: 102px;
                            background-color: #f7f8fd;
                            left: 0;
                            display: none;
                        }
                    }
                    p {
                        margin: 22px 0 0 0;
                        float: right;
                    }
                    .fType {
                        display: none;
                    }
                }
            }
            &.withOtp {
                margin: 20px 0 0 0;
            }
        }
    }
}

.formTotalWrap {
    @include importantWl();
    display: none;
    &.show {
        display: block;
    }
    .subBttns {
        @include importantWl();
        margin: 50px 0 50px 0;
        display: flex;
        flex-flow: column;
        align-items: center;
        .subBttn {
            background: $themeBlue;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.239019);
            border-radius: 28px;
            padding: 15px 52px;
            font-style: normal;
            font-weight: 400;
            font-size: 25px;
            line-height: 16px;
            color: #FFFFFF;
            margin: 0px 0 0 0;
            border: 0;
            outline: none !important;
            cursor: pointer !important;
            font-family: $themeFont;
            &.disabledClass {
                opacity: .4;
                cursor: text !important;
            }
            &.overlap {
                position: relative;
                margin: -46px 0 0 0;
                // background-color: #f00;
            }
        }
        .backbttn {
            background-color: transparent;
            border: 0;
            outline: none !important;
            cursor: pointer !important;
            font-style: normal;
            font-weight: 400;
            font-size: 25px;
            line-height: 29px;
            color: #8C93BB;
            margin: 23px 0 0 0;
            font-family: $themeFont;
        }
    }
}

.checkMarkWrap {
    @include importantWl();
    padding: 110px 0 60px 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin: -60px 0 0 0;
    background-image: url(../images/successBac.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    .checkMark {
        width: 172px;
        height: 172px;
        float: left;
        border-radius: 100%;
        background-color: $themeBlue;
        border: 18px solid #d1d7ff;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        color: #fff;        
        font-size: 70px;
    }
}

.successPageText {
    @include importantWl();
    text-align: center;
    p {
        @include importantWl();
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #8C93BB;
        margin: 35px 0 0 0;
    }
}

.ErrorPopUpWrap {
    width: 100%;
    height: 100vh;
    background: rgba(0, 13, 38, 0.3);
    backdrop-filter: blur(3px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    .errorPopmain {
        width: 740px;
        min-height: 469px;
        position: relative;
        float: left;
        background-color: #fff;
        background-image: url(../images/popBac.png);
        background-size: cover;
        border-radius: 12px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding: 50px 50px;
        .erroricon {
            background: #F44040;
            width: 60px;
            height: 60px;
            border-radius: 100%;
            float: left;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 35px;
        }
        h4 {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 29px;
            text-align: center;
            color: #4E4B66;
            width: 170px;
            float: left;
            margin: 20px 0 0 0;
        }
        p {
            width: 250px;
            float: left;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            color: #4E4B66;
            margin: 14px 0 0 0;
        }
        .subBttn {
            background: $themeBlue;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.239019);
            border-radius: 28px;
            padding: 15px 36px;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 16px;
            color: #FFFFFF;
            margin: 45px 0 0 0;
            border: 0;
            outline: none !important;
            cursor: pointer !important;
        }
        .closePop {
            position: absolute;
            top: 13px;
            left: 19px;
            color: #017DC5;
            font-size: 25px;
            cursor: pointer;
        }
    }
}

.loginWrapper {
    @include importantWl();
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    align-items: center;
    .loginBlockMain {
        width: 320px;
        float: left;
        margin: 70px 0 0 0;
        .logo {
            @include importantWl();
            text-align: center;
            img {
                width: 228px;
            }
        }
        h1 {
            @include importantWl();
            margin: 50px 0 0 0;
            font-size: 28px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: right;
            color: #333;
            small {
                @include importantWl();
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: right;
                color: #333;
                margin: 0px 0 0 0;
            }
        }
        .mainForm {
            @include importantWl();
            margin: 48px 0 0 0;
            .loginFeald {
                @include importantWl();
                margin: 6px 0;
                height: 50px;
                padding: 13px 21px 13px 39px;
                border-radius: 10px;
                background-color: #f7f8fd;
                border: 1px solid #f7f8fd;
                font-size: 16px;
                text-align: right;
                color: #8c93bb;
                outline: none !important;
                font-family: $themeFont;
                transition: 0.3s ease-in-out;
                &::placeholder {
                    color: #8c93bb;
                }        
                &:focus {
                    border: 1px solid #5064de !important;
                    background-color: rgba(80, 100, 222, 0.16);
                }
                &.invalid {
                    background-color: #ffe9e9;
                    border: 1px solid #f00;
                    transition: 0.3s ease-in-out;
                    &::placeholder {
                        color: rgb(185, 0, 0);
                    }   
                }
            }
            .subBttn {
                @include importantWl();
                border: 0;
                outline: none;
                background-color: $themeBlue;
                color: #fff;
                font-family: $themeFont;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 54px 0 0 0;
                font-size: 18px;
                font-weight: bold;
                border-radius: 60px;
                cursor: pointer;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.24);
            }
        }
    }
}

.otpBox {
    @include importantWl();
    margin: 0;
    h4 {
        @include importantWl();
        font-size: 14px;
        font-weight: 400;
        text-align: right;
        color: #8c93bb;
        margin: 0 0 8px 0
    }
    .mainOtpBox {
        @include importantWl();
        display: flex;
        justify-content: space-between;
        direction: ltr;
        input {
            min-width: 47px;
            max-width: 47px;
            height: 91px;
            float: left;
            border-radius: 10px;
            border: 1px solid #f7f8fd;
            background-color: #f7f8fd;
            font-family: $themeFont;
            font-size: 24px;
            color: #333;
            outline: none;            
            &:focus {
                border: 1px solid #5064de;
                background-color: rgba(80, 100, 222, 0.16);
            }
        }
    }
    &.invalid .mainOtpBox input {
        border: 1px solid #ff2727;
        background-color: #ffe7e7;
        transition: 0.3s ease-in-out;
    }
    .subBttn {
        margin: 118px 0 0 0;
    }
    p {
        @include importantWl();
        font-size: 14px;
        line-height: 2.07;
        text-align: center;
        color: #8c93bb;
        margin: 15px 0 0 0;
        a {
            font-weight: 700;
            color: $themeBlue;
        }
    }
}

.thumbnail {
    top: -73px;
    position: absolute;
    width: 130px;
    height: 57px;
    padding: 0 0;
    background: #fff;
    box-shadow: 0px 8px 24px rgba(1, 33, 85, 0.06);
    border-radius: 16px;
    line-height: 59px;
    right: calc(50% - 65px);
    color: #3D69FF;
    font-weight: 700;
    font-size: 16px;
}

.moreInfoPopWrap {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 13, 38, 0.3);
    backdrop-filter: blur(11px);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    .popupContain {
        position: relative;
        width: 299px;
        min-height: 212px;
        background: #FFFFFF;
        border: 2px solid #FFE8B4;
        border-radius: 8px 18px;
        padding: 24px 29px;
        float: left;
        z-index: 100;
        h4 {
            @include importantWl();
            font-family: $themeFont2;
            position: relative;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #4E4B66;
            margin: $clear;
            &::after {
                content: '';
                position: absolute;
                width: 30px;
                height: 2px;
                background-color: #ECECEC;
                bottom: -7px;
                left: calc(50% - 15px);
                border-radius: 2px
            }
        }
        p {
            @include importantWl();
            font-family: $themeFont2;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #4E4B66;
            margin: 20px 0 0 0;
            font-weight: 400;
            b {
                @include importantWl();
                font-weight: 600;
            }
        }
    }
    .closePopOverlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    appearance: textfield;
}