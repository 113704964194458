@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&family=Heebo:wght@100;200;300;400;500;600;800;900&display=swap");
* {
  box-sizing: border-box;
  text-decoration: none !important;
}

.formFildInpt {
  font-family: "Heebo" !important;
}

body {
  width: 100%;
  float: left;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  font-family: "Heebo";
}

header {
  width: 100%;
  float: left;
  padding: 28px 48px;
  background-color: #5064DE;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
header a.logoWrap {
  float: left;
}
header .rightBttns {
  float: left;
  display: flex;
  align-items: center;
  gap: 45px;
  flex-flow: row wrap;
}
header .rightBttns a.btnStyle {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  color: #5064DE;
  background-color: #fff;
  padding: 7px 13px;
  border-radius: 33px;
}
header .rightBttns a.linkStyle {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  color: #FFFFFF;
}

.dashboardWrap {
  width: 100%;
  float: left;
  margin: 70px 0 0 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.dashboardWrap .dbContainer {
  width: 895px;
  float: left;
}
.dashboardWrap .dbContainer .dbinner {
  width: 100%;
  float: left;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.dashboardWrap .dbContainer .dbinner picture {
  width: 100%;
  float: left;
  text-align: center;
}
.dashboardWrap .dbContainer .dbinner h1 {
  width: 100%;
  float: left;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  color: #375177;
  margin: 0 0 40px 0;
}
.dashboardWrap .dbContainer .dbinner h1 small {
  max-width: 480px;
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 39px;
  text-align: center;
  color: #39546A;
  margin: 20px 0 0 0;
}
.dashboardWrap .dbContainer .dbinner h3 {
  width: 100%;
  float: left;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 31px;
  color: #39546A;
  margin: 5px 0;
}
.dashboardWrap .dbContainer .dbinner p {
  width: 100%;
  float: left;
  margin: 45px 0 0 0;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #39546A;
}
.dashboardWrap .dbContainer .dbinner a.blueBttnStyle {
  background: #5064DE;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.239019);
  border-radius: 28px;
  padding: 15px 52px;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 16px;
  color: #FFFFFF;
  margin: 66px 0 0 0;
}
.dashboardWrap .dbContainer .dbinner .linkStyle {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
  margin: 26px 0 50px 0;
  color: #8C93BB;
}

.completeRiskPageWrap {
  width: 100%;
  float: left;
  padding: 103px 0 50px 0;
  display: flex;
  align-items: center;
  flex-flow: column;
  background-color: #f8f8ff;
  min-height: calc(100vh - 117px);
}
.completeRiskPageWrap .completeRiskPageContainer {
  width: 722px;
  float: left;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .rpTopBox {
  width: 100%;
  float: left;
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(1, 33, 85, 0.06);
  border-radius: 16px;
  min-height: 280px;
  padding: 32px 0;
  display: flex;
  align-items: center;
  flex-flow: column;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .rpTopBox picture {
  width: 100%;
  float: left;
  text-align: center;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .rpTopBox h2 {
  width: 100%;
  float: left;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 23px;
  color: #375177;
  margin: 5px 0 0 0;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .rpTopBox p {
  width: 100%;
  float: left;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #4E4B66;
  margin: 20px 0 0 0;
  font-family: "Assistant";
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .noticeWrap {
  width: 440px;
  padding: 18px 15px;
  background-color: #FFE8B4;
  border-radius: 9px 20px 9px 20px;
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 40px 0 0 0;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .noticeWrap img {
  width: 18px;
  height: 18px;
  float: left;
  -o-object-fit: cover;
     object-fit: cover;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .noticeWrap p {
  float: left;
  margin: 0 0;
  font-family: "Assistant";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #4E4B66;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .noticeWrap a {
  color: #4E4B66;
  text-decoration: underline !important;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .sliderCompleteWrap {
  width: 100%;
  float: left;
  margin: 40px 0 0 0;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .sliderCompleteWrap h1 {
  width: 100%;
  float: left;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 23px;
  color: #375177;
  text-align: center;
  margin: 0 0 0 0;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .sliderCompleteWrap h1 small {
  width: 100%;
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 23px;
  text-align: center;
  color: #375177;
  margin: 17px 0 0 0;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .sliderCompleteWrap .reactSlider {
  width: 100%;
  float: left;
  margin: 100px 0 0 0;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .sliderCompleteWrap .reactSlider .horizontal-slider {
  width: 100%;
  float: left;
  height: 50px;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .sliderCompleteWrap .reactSlider .horizontal-slider .example-thumb {
  top: -5px;
  width: 49px;
  outline: none;
  height: 49px;
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #1070C8;
  border: 2px solid #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 100%;
  display: block;
  line-height: 76px;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .sliderCompleteWrap .reactSlider .horizontal-slider .example-thumb span.thumbnail {
  background-color: #fff;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .sliderCompleteWrap .reactSlider .horizontal-slider .example-track {
  position: relative;
  background: #EDEEF1;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .sliderCompleteWrap .reactSlider .horizontal-slider .example-track.example-track-1 {
  background: #EDEEF1;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .sliderCompleteWrap .reactSlider .horizontal-slider .example-track-2 {
  background: #EDEEF1;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .sliderCompleteWrap .reactSlider .horizontal-slider .example-track {
  top: 14px;
  height: 13px;
  border-radius: 20px;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .sliderCompleteWrap .reactSlider .horizontal-slider .example-mark {
  width: 9px;
  height: 9px;
  background-color: #1070C8;
  border-radius: 100%;
  margin: 0 0 0 17px;
  top: 16px;
  opacity: 0.4;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .sliderCompleteWrap .reactSlider .slideMarks {
  width: 100%;
  float: left;
  display: flex;
  margin: 8px 0 0 0;
  justify-content: space-between;
  flex-flow: row-reverse;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .sliderCompleteWrap .reactSlider .slideMarks p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #012155;
  margin: 0 0;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .sliderCompleteWrap .reactSlider .slideMarks input::-webkit-outer-spin-button,
.completeRiskPageWrap .completeRiskPageContainer .rpinner .sliderCompleteWrap .reactSlider .slideMarks input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .sliderCompleteWrap .reactSlider .slideMarks input[type=number] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner .sliderCompleteWrap .reactSlider .slideMarks .inputNumberType {
  width: 130px;
  height: 50px;
  float: left;
  padding: 0 0;
  background: #fff;
  box-shadow: 0px 8px 24px rgba(1, 33, 85, 0.15);
  border-radius: 12px;
  color: #3D69FF;
  font-weight: 700;
  font-size: 16px;
  float: left;
  border: 0;
  text-align: center;
  outline: none;
}
.completeRiskPageWrap .completeRiskPageContainer .rpinner a.bluebttnStyle {
  background: #5064DE;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.239019);
  border-radius: 28px;
  padding: 15px 52px;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 16px;
  color: #FFFFFF;
  margin: 120px 0 0 0;
}

.investmentTotalWrap {
  width: 100%;
  float: left;
  min-height: calc(100vh - 117px);
  background-color: #f8f8ff;
  display: flex;
  justify-content: center;
}
.investmentTotalWrap .ipContainer {
  width: 820px;
  float: left;
}
.investmentTotalWrap .ipContainer .ipInner {
  width: 100%;
  float: left;
  margin: 102px 0 50px 0;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.investmentTotalWrap .ipContainer .ipInner h1 {
  width: 100%;
  float: left;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 23px;
  text-align: center;
  color: #375177;
  margin: 0 0 0 0;
}
.investmentTotalWrap .ipContainer .ipInner .depositAmmount {
  width: 100%;
  float: left;
  background-color: #fff;
  margin: 50px 0 0 0;
  padding: 32px 0;
  box-shadow: 0px 8px 24px rgba(1, 33, 85, 0.06);
  border-radius: 16px;
}
.investmentTotalWrap .ipContainer .ipInner .depositAmmount h2 {
  width: 100%;
  float: left;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 23px;
  color: #375177;
  margin: 0 0 0 0;
}
.investmentTotalWrap .ipContainer .ipInner .depositAmmount h2 small {
  width: 100%;
  float: left;
  margin: 0 0 20px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 18px;
  color: #012155;
}
.investmentTotalWrap .ipContainer .ipInner .depositAmmount p {
  width: 100%;
  float: left;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 24px;
  text-align: center;
  color: #012155;
  margin: 8px 0 0 0;
}
.investmentTotalWrap .ipContainer .ipInner .pieChart {
  width: 337px;
  float: left;
  margin: 105px 0 100px 0;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
  padding: 16px 16px 10px 16px;
  position: relative;
}
.investmentTotalWrap .ipContainer .ipInner .pieChart svg path {
  border-radius: 30px;
}
.investmentTotalWrap .ipContainer .ipInner .pieChart .label_one {
  position: absolute;
  top: -51px;
  right: -110px;
}
.investmentTotalWrap .ipContainer .ipInner .pieChart .label_one h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  text-align: right;
  letter-spacing: -0.5px;
  color: #333333;
  margin: 0;
  display: flex;
  flex-flow: column;
}
.investmentTotalWrap .ipContainer .ipInner .pieChart .label_one h4 small {
  width: 100%;
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #333333;
}
.investmentTotalWrap .ipContainer .ipInner .pieChart .label_one::after {
  content: "";
  position: absolute;
  right: 40px;
  top: 74px;
  width: 136px;
  height: 23px;
  background-image: url(../images/line_01.svg);
  background-size: cover;
  background-repeat: no-repeat;
}
.investmentTotalWrap .ipContainer .ipInner .pieChart .label_two {
  position: absolute;
  top: -51px;
  left: -110px;
}
.investmentTotalWrap .ipContainer .ipInner .pieChart .label_two h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  text-align: right;
  letter-spacing: -0.5px;
  color: #333333;
  margin: 0;
  display: flex;
  flex-flow: column;
}
.investmentTotalWrap .ipContainer .ipInner .pieChart .label_two h4 small {
  width: 100%;
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #333333;
}
.investmentTotalWrap .ipContainer .ipInner .pieChart .label_two::after {
  content: "";
  position: absolute;
  left: 40px;
  top: 74px;
  width: 136px;
  height: 23px;
  background-image: url(../images/line_02.svg);
  background-size: cover;
  background-repeat: no-repeat;
}
.investmentTotalWrap .ipContainer .ipInner .pieChart .label_three {
  position: absolute;
  bottom: -51px;
  right: -110px;
}
.investmentTotalWrap .ipContainer .ipInner .pieChart .label_three h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  text-align: right;
  letter-spacing: -0.5px;
  color: #333333;
  margin: 0;
  display: flex;
  flex-flow: column;
}
.investmentTotalWrap .ipContainer .ipInner .pieChart .label_three h4 small {
  width: 100%;
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #333333;
}
.investmentTotalWrap .ipContainer .ipInner .pieChart .label_three::after {
  content: "";
  position: absolute;
  right: 40px;
  top: -49px;
  width: 136px;
  height: 23px;
  background-image: url(../images/line_01.svg);
  background-size: cover;
  background-repeat: no-repeat;
  transform: rotateX(180deg);
}
.investmentTotalWrap .ipContainer .ipInner .pieChart .label_four {
  position: absolute;
  bottom: -51px;
  left: -110px;
}
.investmentTotalWrap .ipContainer .ipInner .pieChart .label_four h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  text-align: right;
  letter-spacing: -0.5px;
  color: #333333;
  margin: 0;
  display: flex;
  flex-flow: column;
}
.investmentTotalWrap .ipContainer .ipInner .pieChart .label_four h4 small {
  width: 100%;
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #333333;
}
.investmentTotalWrap .ipContainer .ipInner .pieChart .label_four::after {
  content: "";
  position: absolute;
  left: 40px;
  top: -49px;
  width: 136px;
  height: 23px;
  background-image: url(../images/line_02.svg);
  background-size: cover;
  background-repeat: no-repeat;
  transform: rotateX(180deg);
}
.investmentTotalWrap .ipContainer .ipInner .ramgeSliderWrap {
  width: 670px;
  float: left;
}
.investmentTotalWrap .ipContainer .ipInner .ramgeSliderWrap h2 {
  width: 100%;
  float: left;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 23px;
  color: #333333;
  margin: 0 0;
}
.investmentTotalWrap .ipContainer .ipInner .ramgeSliderWrap .rSlid {
  width: 100%;
  float: left;
  position: relative;
  margin: 100px 0 0 0;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.investmentTotalWrap .ipContainer .ipInner .ramgeSliderWrap .rSlid .horizontal-slider {
  width: 100%;
  float: left;
  height: 50px;
}
.investmentTotalWrap .ipContainer .ipInner .ramgeSliderWrap .rSlid .horizontal-slider .example-thumb {
  top: -5px;
  width: 49px;
  outline: none;
  height: 49px;
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #1070C8;
  border: 2px solid #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 100%;
  display: block;
  line-height: 76px;
  font-weight: 500;
  text-align: center;
  transition: 0.3s ease-in-out;
}
.investmentTotalWrap .ipContainer .ipInner .ramgeSliderWrap .rSlid .horizontal-slider .example-thumb span {
  position: absolute;
  background-color: #fff;
}
.investmentTotalWrap .ipContainer .ipInner .ramgeSliderWrap .rSlid .horizontal-slider .example-track {
  position: relative;
  background: #EDEEF1;
}
.investmentTotalWrap .ipContainer .ipInner .ramgeSliderWrap .rSlid .horizontal-slider .example-track.example-track-1 {
  background: #EDEEF1;
}
.investmentTotalWrap .ipContainer .ipInner .ramgeSliderWrap .rSlid .horizontal-slider .example-track-2 {
  background: #EDEEF1;
}
.investmentTotalWrap .ipContainer .ipInner .ramgeSliderWrap .rSlid .horizontal-slider .example-track {
  top: 14px;
  height: 13px;
  border-radius: 20px;
}
.investmentTotalWrap .ipContainer .ipInner .ramgeSliderWrap .rSlid .horizontal-slider .example-mark {
  width: 16px;
  height: 16px;
  background-color: #1070C8;
  border-radius: 100%;
  margin: 0 0 0 17px;
  top: 12px;
  opacity: 0.4;
}
.investmentTotalWrap .ipContainer .ipInner .ramgeSliderWrap .rSlid .arealabel {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  flex-flow: row-reverse;
  margin: 15px 0 0 0;
}
.investmentTotalWrap .ipContainer .ipInner .ramgeSliderWrap .rSlid .arealabel p {
  max-width: 50px;
  float: left;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 15px;
  color: #012155;
  margin: 0 0;
}
.investmentTotalWrap .ipContainer .ipInner .ramgeSliderWrap .rSlid .riskLevelLebel {
  width: 299px;
  height: 56px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border: 2px solid #FFE8B4;
  border-radius: 8px 18px;
  font-family: "Assistant";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #4E4B66;
  margin: 44px 0 0 0;
  float: left;
  position: relative;
  padding: 0 0 13px 0;
  outline: none !important;
  cursor: pointer;
}
.investmentTotalWrap .ipContainer .ipInner .ramgeSliderWrap .rSlid .riskLevelLebel::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 2px;
  background-color: #ECECEC;
  bottom: 13px;
}
.investmentTotalWrap .ipContainer .ipInner .riskLevelCard {
  width: 100%;
  float: left;
  margin: 30px 0 30px 0;
  min-height: 275px;
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(1, 33, 85, 0.06);
  border-radius: 16px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.investmentTotalWrap .ipContainer .ipInner .riskLevelCard img {
  width: 101px;
}
.investmentTotalWrap .ipContainer .ipInner .riskLevelCard h4 {
  width: 255px;
  float: left;
  margin: 10px 0;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 23px;
  text-align: center;
  color: #375177;
}
.investmentTotalWrap .ipContainer .ipInner .riskLevelCard p {
  max-width: 620px;
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #4E4B66;
  font-family: "Assistant";
  margin: 0 0 0 0;
}
.investmentTotalWrap .ipContainer .ipInner .confirmationWrap {
  width: 670px;
  float: left;
  margin: 30px 0 0px 0;
}
.investmentTotalWrap .ipContainer .ipInner .confirmationWrap p {
  width: 100%;
  float: left;
  margin: 0 0 23px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 23px;
  color: #375177;
}
.investmentTotalWrap .ipContainer .ipInner .confirmationWrap .radioBox {
  width: 100%;
  float: left;
}
.investmentTotalWrap .ipContainer .ipInner .confirmationWrap .radioBox label {
  float: right;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 0 0 35px;
  cursor: pointer;
}
.investmentTotalWrap .ipContainer .ipInner .confirmationWrap .radioBox label span {
  width: 48px;
  height: 48px;
  border: 2px solid #5064DE;
  float: right;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.investmentTotalWrap .ipContainer .ipInner .confirmationWrap .radioBox label span b.mark {
  width: 28px;
  height: 28px;
  background-color: #5064DE;
  float: left;
  border-radius: 100%;
  transition: 0.3s ease-in-out;
  transform: scale(0);
}
.investmentTotalWrap .ipContainer .ipInner .confirmationWrap .radioBox label input[type=radio] {
  display: none;
}
.investmentTotalWrap .ipContainer .ipInner .confirmationWrap .radioBox label input[type=radio]:checked ~ span b {
  transform: scale(1);
}
.investmentTotalWrap .ipContainer .ipInner .confirmationWrap .radioBox label h5 {
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin: 0 0 0 0;
  color: #333333;
}
.investmentTotalWrap .ipContainer .ipInner .subBttns {
  width: 100%;
  float: left;
  margin: 50px 0 0px 0;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.investmentTotalWrap .ipContainer .ipInner .subBttns .subBttn {
  background: #5064DE;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.239019);
  border-radius: 28px;
  padding: 15px 52px;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 16px;
  color: #FFFFFF;
  margin: 0px 0 0 0;
  border: 0;
  outline: none !important;
  cursor: pointer !important;
  font-family: "Heebo";
}
.investmentTotalWrap .ipContainer .ipInner .subBttns .backbttn {
  background-color: transparent;
  border: 0;
  outline: none !important;
  cursor: pointer !important;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
  color: #8C93BB;
  margin: 23px 0 0 0;
  font-family: "Heebo";
}

.riskPopWrap {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 13, 38, 0.3);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.riskPopWrap .rPopMain {
  width: 796px;
  min-height: 653px;
  background-color: #fff;
  border-radius: 8px;
  background-image: url(../images/popBac.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.riskPopWrap .rPopMain .removePop {
  position: absolute;
  top: 18px;
  left: 23px;
  font-size: 25px;
  color: #017DC5;
  cursor: pointer;
}
.riskPopWrap .rPopMain h4 {
  width: 100%;
  float: left;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 47px;
  text-align: center;
  color: #4E4B66;
  margin: 12px 0 0 0;
}
.riskPopWrap .rPopMain .rSlid {
  width: 100%;
  float: left;
  position: relative;
  margin: 80px 0 0 0;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.riskPopWrap .rPopMain .rSlid .horizontal-slider {
  width: 100%;
  float: left;
  height: 50px;
}
.riskPopWrap .rPopMain .rSlid .horizontal-slider .example-thumb {
  top: -5px;
  width: 49px;
  outline: none;
  height: 49px;
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #1070C8;
  border: 2px solid #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 100%;
  display: block;
  line-height: 76px;
  font-weight: 500;
  text-align: center;
  transition: 0.3s ease-in-out;
}
.riskPopWrap .rPopMain .rSlid .horizontal-slider .example-track {
  position: relative;
  background: #EDEEF1;
}
.riskPopWrap .rPopMain .rSlid .horizontal-slider .example-track.example-track-1 {
  background: #EDEEF1;
}
.riskPopWrap .rPopMain .rSlid .horizontal-slider .example-track-2 {
  background: #EDEEF1;
}
.riskPopWrap .rPopMain .rSlid .horizontal-slider .example-track {
  top: 14px;
  height: 13px;
  border-radius: 20px;
}
.riskPopWrap .rPopMain .rSlid .horizontal-slider .example-mark {
  width: 16px;
  height: 16px;
  background-color: #1070C8;
  border-radius: 100%;
  margin: 0 0 0 17px;
  top: 12px;
  opacity: 0.4;
}
.riskPopWrap .rPopMain .rSlid .arealabel {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  flex-flow: row-reverse;
  margin: 15px 0 0 0;
}
.riskPopWrap .rPopMain .rSlid .arealabel p {
  max-width: 50px;
  float: left;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 15px;
  color: #012155;
  margin: 0 0;
}
.riskPopWrap .rPopMain .subBttn {
  background: #5064DE;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.239019);
  border-radius: 35px;
  padding: 11px 62px 20px 62px;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  color: #FFFFFF;
  margin: 60px 0 0 0;
  border: 0;
  outline: none !important;
  cursor: pointer !important;
  font-family: "Heebo" !important;
}

.completeFormBlock {
  width: 100%;
  float: left;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.completeFormBlock .formInner {
  width: 785px;
  float: left;
  margin: 40px 0 0 0;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.completeFormBlock .formInner .countBar {
  width: 390px;
  float: left;
  display: flex;
  justify-content: space-between;
  flex-flow: row-reverse;
  align-items: center;
  position: relative;
  margin: 0 0 60px 0;
}
.completeFormBlock .formInner .countBar button {
  width: 31px;
  height: 31px;
  float: left;
  border-radius: 100%;
  border: 4px solid rgb(170, 179, 235);
  background-color: #fff;
  position: relative;
  z-index: 1;
}
.completeFormBlock .formInner .countBar button.active {
  cursor: default;
}
.completeFormBlock .formInner .countBar button.active::after {
  content: "";
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  background-color: #5064DE;
  top: 5px;
  left: 5px;
}
.completeFormBlock .formInner .countBar:after {
  width: 100%;
  content: "";
  position: absolute;
  border-top: 3px dashed rgb(170, 179, 235);
  top: 14px;
  left: 0;
}
.completeFormBlock .formInner h2 {
  width: 100%;
  float: left;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 23px;
  color: #375177;
  margin: 20px 0 0 0;
}
.completeFormBlock .formInner .mainForm {
  width: 100%;
  float: left;
  margin: 55px 0 0 0;
}
.completeFormBlock .formInner .mainForm .formFildDouble {
  width: 100%;
  float: left;
  display: flex;
  gap: 24px;
  flex-flow: row wrap;
  margin: 0 0 10px 0;
}
.completeFormBlock .formInner .mainForm .formFildDouble .formFildInpt {
  width: calc(50% - 12px);
  float: left;
  background: #F7F8FD;
  border-radius: 10px;
  border: 1px solid #F7F8FD;
  height: 70px;
  outline: none !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #8C93BB;
  padding: 0 40px;
  color: #8C93BB;
}
.completeFormBlock .formInner .mainForm .formFildDouble .formFildInpt:focus {
  border: 1px solid #5064de !important;
  background-color: rgba(80, 100, 222, 0.16);
}
.completeFormBlock .formInner .mainForm .formFildDouble .formFildInpt::-moz-placeholder {
  color: #8C93BB;
}
.completeFormBlock .formInner .mainForm .formFildDouble .formFildInpt::placeholder {
  color: #8C93BB;
}
.completeFormBlock .formInner .mainForm .formFildDouble .formFildInpt:focus {
  border: 1px solid #5064de !important;
  background-color: rgba(80, 100, 222, 0.16) !important;
}
.completeFormBlock .formInner .mainForm .formFildSingles {
  width: 100%;
  float: left;
  margin: 30px 0 0 0;
}
.completeFormBlock .formInner .mainForm .formFildSingles.smallMargin {
  margin: 0 0 0 0;
}
.completeFormBlock .formInner .mainForm .formFildSingles .formFildInpt {
  width: 100%;
  float: left;
  background: #F7F8FD;
  border-radius: 10px;
  border: 1px solid #F7F8FD;
  height: 70px;
  outline: none !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #8C93BB;
  padding: 0 40px;
  color: #8C93BB;
  margin: 10px 0;
}
.completeFormBlock .formInner .mainForm .formFildSingles .formFildInpt::-moz-placeholder {
  color: #8C93BB;
}
.completeFormBlock .formInner .mainForm .formFildSingles .formFildInpt::placeholder {
  color: #8C93BB;
}
.completeFormBlock .formInner .mainForm .formFildSingles .formFildInpt:focus {
  border: 1px solid #5064de !important;
  background-color: rgba(80, 100, 222, 0.16);
}
.completeFormBlock .formInner .mainForm .formFildSingles select.formFildInpt {
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  background-image: url(../images/selectArrow.svg);
  background-repeat: no-repeat;
  background-position: 40px 50%;
}
.completeFormBlock .formInner .mainForm .completeRadioWrap {
  width: 100%;
  float: left;
  margin: 30px 0 20px 0;
}
.completeFormBlock .formInner .mainForm .completeRadioWrap p {
  width: 100%;
  float: left;
  margin: 0 0 23px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 23px;
  color: #375177;
}
.completeFormBlock .formInner .mainForm .completeRadioWrap .radioBox {
  width: 100%;
  float: left;
}
.completeFormBlock .formInner .mainForm .completeRadioWrap .radioBox label {
  float: right;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 0 0 35px;
  cursor: pointer;
}
.completeFormBlock .formInner .mainForm .completeRadioWrap .radioBox label span {
  width: 48px;
  height: 48px;
  border: 2px solid #5064DE;
  float: right;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.completeFormBlock .formInner .mainForm .completeRadioWrap .radioBox label span b.mark {
  width: 28px;
  height: 28px;
  background-color: #5064DE;
  float: left;
  border-radius: 100%;
  transition: 0.3s ease-in-out;
  transform: scale(0);
}
.completeFormBlock .formInner .mainForm .completeRadioWrap .radioBox label input[type=radio] {
  display: none;
}
.completeFormBlock .formInner .mainForm .completeRadioWrap .radioBox label input[type=radio]:checked ~ span b {
  transform: scale(1);
}
.completeFormBlock .formInner .mainForm .completeRadioWrap .radioBox label h5 {
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin: 0 0 0 0;
  color: #333333;
}
.completeFormBlock .formInner .mainForm .confirmCheckBoxWrap {
  width: 100%;
  float: left;
  margin: 30px 0 20px 0;
  position: relative;
}
.completeFormBlock .formInner .mainForm .confirmCheckBoxWrap.addOverlay::after {
  content: "";
  width: 100%;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  z-index: 99;
  top: 111px;
  left: 0;
}
.completeFormBlock .formInner .mainForm .confirmCheckBoxWrap label {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
}
.completeFormBlock .formInner .mainForm .confirmCheckBoxWrap label p {
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #8E95BC;
  margin: 0 0 0 0;
}
.completeFormBlock .formInner .mainForm .confirmCheckBoxWrap label span {
  float: left;
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  background: #F7F8FD;
  border: 2px solid #5064DE;
  border-radius: 6px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.completeFormBlock .formInner .mainForm .confirmCheckBoxWrap label span svg {
  color: #5064DE;
  font-size: 30px;
  transform-origin: bottom left;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.completeFormBlock .formInner .mainForm .confirmCheckBoxWrap label .chackBox {
  display: none;
}
.completeFormBlock .formInner .mainForm .confirmCheckBoxWrap label .chackBox:checked ~ span svg {
  transform: scale(1);
}
.completeFormBlock .formInner .mainForm .spouceInfo {
  width: 100%;
  float: left;
}
.completeFormBlock .formInner .mainForm .spouceInfo h2 {
  margin: 20px 0 40px 0;
}
.completeFormBlock .formInner .mainForm .smallHalfWrapper {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
.completeFormBlock .formInner .mainForm .smallHalfWrapper .shBlocks {
  width: calc(100% - 220px);
  float: left;
}
.completeFormBlock .formInner .mainForm .smallHalfWrapper .shBlocks.small {
  width: 175px;
}
.completeFormBlock .formInner .mainForm .smallHalfWrapper .shBlocks span {
  width: 100%;
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 23px;
  color: #375177;
  margin: 0 0 8px 0;
}
.completeFormBlock .formInner .mainForm .smallHalfWrapper .shBlocks .formFildInpt {
  width: 100%;
  float: left;
  background: #F7F8FD;
  border-radius: 10px;
  border: 1px solid #F7F8FD;
  height: 70px;
  outline: none !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #8C93BB;
  padding: 0 40px;
  margin: 10px 0;
}
.completeFormBlock .formInner .mainForm .smallHalfWrapper .shBlocks .formFildInpt::-moz-placeholder {
  color: #8C93BB;
}
.completeFormBlock .formInner .mainForm .smallHalfWrapper .shBlocks .formFildInpt::placeholder {
  color: #8C93BB;
}
.completeFormBlock .formInner .mainForm .smallHalfWrapper .shBlocks .formFildInpt:focus {
  border: 1px solid #5064de !important;
  background-color: rgba(80, 100, 222, 0.16);
}
.completeFormBlock .formInner .mainForm .smallHalfWrapper .shBlocks select.formFildInpt {
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
  background-image: url(../images/selectArrow.svg);
  background-repeat: no-repeat;
  background-position: 40px 50%;
}
.completeFormBlock .formInner .mainForm .uploadTypeFormFeald {
  width: 100%;
  float: left;
  margin: 20px 0 0 0;
}
.completeFormBlock .formInner .mainForm .uploadTypeFormFeald .fealdType {
  width: 100%;
  float: left;
  background: #F7F8FD;
  border-radius: 10px;
  border: 1px solid #F7F8FD;
  height: 70px;
  outline: none !important;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  color: #8C93BB;
  padding: 0 40px;
  margin: 10px 0;
  cursor: pointer;
  background-image: url(../images/uploadicon.svg);
  background-repeat: no-repeat;
  background-position: 30px 50%;
  position: relative;
}
.completeFormBlock .formInner .mainForm .uploadTypeFormFeald .fealdType b {
  font-weight: 400;
  top: 18px;
  right: 40px;
  position: absolute;
  width: 70%;
  background: #F7F8FD;
}
.completeFormBlock .formInner .mainForm .uploadTypeFormFeald .fealdType b::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 102px;
  background-color: #f7f8fd;
  left: 0;
  display: none;
}
.completeFormBlock .formInner .mainForm .uploadTypeFormFeald .fealdType p {
  margin: 22px 0 0 0;
  float: right;
}
.completeFormBlock .formInner .mainForm .uploadTypeFormFeald .fealdType .fType {
  display: none;
}
.completeFormBlock .formInner .mainForm.withOtp {
  margin: 20px 0 0 0;
}

.formTotalWrap {
  width: 100%;
  float: left;
  display: none;
}
.formTotalWrap.show {
  display: block;
}
.formTotalWrap .subBttns {
  width: 100%;
  float: left;
  margin: 50px 0 50px 0;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.formTotalWrap .subBttns .subBttn {
  background: #5064DE;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.239019);
  border-radius: 28px;
  padding: 15px 52px;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 16px;
  color: #FFFFFF;
  margin: 0px 0 0 0;
  border: 0;
  outline: none !important;
  cursor: pointer !important;
  font-family: "Heebo";
}
.formTotalWrap .subBttns .subBttn.disabledClass {
  opacity: 0.4;
  cursor: text !important;
}
.formTotalWrap .subBttns .subBttn.overlap {
  position: relative;
  margin: -46px 0 0 0;
}
.formTotalWrap .subBttns .backbttn {
  background-color: transparent;
  border: 0;
  outline: none !important;
  cursor: pointer !important;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
  color: #8C93BB;
  margin: 23px 0 0 0;
  font-family: "Heebo";
}

.checkMarkWrap {
  width: 100%;
  float: left;
  padding: 110px 0 60px 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: -60px 0 0 0;
  background-image: url(../images/successBac.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.checkMarkWrap .checkMark {
  width: 172px;
  height: 172px;
  float: left;
  border-radius: 100%;
  background-color: #5064DE;
  border: 18px solid #d1d7ff;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 70px;
}

.successPageText {
  width: 100%;
  float: left;
  text-align: center;
}
.successPageText p {
  width: 100%;
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #8C93BB;
  margin: 35px 0 0 0;
}

.ErrorPopUpWrap {
  width: 100%;
  height: 100vh;
  background: rgba(0, 13, 38, 0.3);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.ErrorPopUpWrap .errorPopmain {
  width: 740px;
  min-height: 469px;
  position: relative;
  float: left;
  background-color: #fff;
  background-image: url(../images/popBac.png);
  background-size: cover;
  border-radius: 12px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 50px 50px;
}
.ErrorPopUpWrap .errorPopmain .erroricon {
  background: #F44040;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  float: left;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 35px;
}
.ErrorPopUpWrap .errorPopmain h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  color: #4E4B66;
  width: 170px;
  float: left;
  margin: 20px 0 0 0;
}
.ErrorPopUpWrap .errorPopmain p {
  width: 250px;
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #4E4B66;
  margin: 14px 0 0 0;
}
.ErrorPopUpWrap .errorPopmain .subBttn {
  background: #5064DE;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.239019);
  border-radius: 28px;
  padding: 15px 36px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  color: #FFFFFF;
  margin: 45px 0 0 0;
  border: 0;
  outline: none !important;
  cursor: pointer !important;
}
.ErrorPopUpWrap .errorPopmain .closePop {
  position: absolute;
  top: 13px;
  left: 19px;
  color: #017DC5;
  font-size: 25px;
  cursor: pointer;
}

.loginWrapper {
  width: 100%;
  float: left;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.loginWrapper .loginBlockMain {
  width: 320px;
  float: left;
  margin: 70px 0 0 0;
}
.loginWrapper .loginBlockMain .logo {
  width: 100%;
  float: left;
  text-align: center;
}
.loginWrapper .loginBlockMain .logo img {
  width: 228px;
}
.loginWrapper .loginBlockMain h1 {
  width: 100%;
  float: left;
  margin: 50px 0 0 0;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #333;
}
.loginWrapper .loginBlockMain h1 small {
  width: 100%;
  float: left;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #333;
  margin: 0px 0 0 0;
}
.loginWrapper .loginBlockMain .mainForm {
  width: 100%;
  float: left;
  margin: 48px 0 0 0;
}
.loginWrapper .loginBlockMain .mainForm .loginFeald {
  width: 100%;
  float: left;
  margin: 6px 0;
  height: 50px;
  padding: 13px 21px 13px 39px;
  border-radius: 10px;
  background-color: #f7f8fd;
  border: 1px solid #f7f8fd;
  font-size: 16px;
  text-align: right;
  color: #8c93bb;
  outline: none !important;
  font-family: "Heebo";
  transition: 0.3s ease-in-out;
}
.loginWrapper .loginBlockMain .mainForm .loginFeald::-moz-placeholder {
  color: #8c93bb;
}
.loginWrapper .loginBlockMain .mainForm .loginFeald::placeholder {
  color: #8c93bb;
}
.loginWrapper .loginBlockMain .mainForm .loginFeald:focus {
  border: 1px solid #5064de !important;
  background-color: rgba(80, 100, 222, 0.16);
}
.loginWrapper .loginBlockMain .mainForm .loginFeald.invalid {
  background-color: #ffe9e9;
  border: 1px solid #f00;
  transition: 0.3s ease-in-out;
}
.loginWrapper .loginBlockMain .mainForm .loginFeald.invalid::-moz-placeholder {
  color: rgb(185, 0, 0);
}
.loginWrapper .loginBlockMain .mainForm .loginFeald.invalid::placeholder {
  color: rgb(185, 0, 0);
}
.loginWrapper .loginBlockMain .mainForm .subBttn {
  width: 100%;
  float: left;
  border: 0;
  outline: none;
  background-color: #5064DE;
  color: #fff;
  font-family: "Heebo";
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 54px 0 0 0;
  font-size: 18px;
  font-weight: bold;
  border-radius: 60px;
  cursor: pointer;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.24);
}

.otpBox {
  width: 100%;
  float: left;
  margin: 0;
}
.otpBox h4 {
  width: 100%;
  float: left;
  font-size: 14px;
  font-weight: 400;
  text-align: right;
  color: #8c93bb;
  margin: 0 0 8px 0;
}
.otpBox .mainOtpBox {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  direction: ltr;
}
.otpBox .mainOtpBox input {
  min-width: 47px;
  max-width: 47px;
  height: 91px;
  float: left;
  border-radius: 10px;
  border: 1px solid #f7f8fd;
  background-color: #f7f8fd;
  font-family: "Heebo";
  font-size: 24px;
  color: #333;
  outline: none;
}
.otpBox .mainOtpBox input:focus {
  border: 1px solid #5064de;
  background-color: rgba(80, 100, 222, 0.16);
}
.otpBox.invalid .mainOtpBox input {
  border: 1px solid #ff2727;
  background-color: #ffe7e7;
  transition: 0.3s ease-in-out;
}
.otpBox .subBttn {
  margin: 118px 0 0 0;
}
.otpBox p {
  width: 100%;
  float: left;
  font-size: 14px;
  line-height: 2.07;
  text-align: center;
  color: #8c93bb;
  margin: 15px 0 0 0;
}
.otpBox p a {
  font-weight: 700;
  color: #5064DE;
}

.thumbnail {
  top: -73px;
  position: absolute;
  width: 130px;
  height: 57px;
  padding: 0 0;
  background: #fff;
  box-shadow: 0px 8px 24px rgba(1, 33, 85, 0.06);
  border-radius: 16px;
  line-height: 59px;
  right: calc(50% - 65px);
  color: #3D69FF;
  font-weight: 700;
  font-size: 16px;
}

.moreInfoPopWrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 13, 38, 0.3);
  -webkit-backdrop-filter: blur(11px);
          backdrop-filter: blur(11px);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.moreInfoPopWrap .popupContain {
  position: relative;
  width: 299px;
  min-height: 212px;
  background: #FFFFFF;
  border: 2px solid #FFE8B4;
  border-radius: 8px 18px;
  padding: 24px 29px;
  float: left;
  z-index: 100;
}
.moreInfoPopWrap .popupContain h4 {
  width: 100%;
  float: left;
  font-family: "Assistant";
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #4E4B66;
  margin: 0 0 0 0;
}
.moreInfoPopWrap .popupContain h4::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 2px;
  background-color: #ECECEC;
  bottom: -7px;
  left: calc(50% - 15px);
  border-radius: 2px;
}
.moreInfoPopWrap .popupContain p {
  width: 100%;
  float: left;
  font-family: "Assistant";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #4E4B66;
  margin: 20px 0 0 0;
  font-weight: 400;
}
.moreInfoPopWrap .popupContain p b {
  width: 100%;
  float: left;
  font-weight: 600;
}
.moreInfoPopWrap .closePopOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}/*# sourceMappingURL=style.css.map */