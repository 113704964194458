@media screen and (min-width: 240px) and (max-width: 1000px) {
  .completeFormBlock {
    width: 100%;
    padding: 0 12px;
  }
  .completeFormBlock .formInner {
    width: 100%;
  }
  .completeFormBlock .formInner .countBar {
    width: 100%;
  }
  .completeFormBlock .formInner .mainForm {
    position: relative;
  }
  .completeFormBlock .formInner .mainForm .smallHalfWrapper {
    margin: 6px 0;
  }
  .completeFormBlock .formInner .mainForm .smallHalfWrapper .shBlocks {
    width: 68% !important;
  }
  .completeFormBlock .formInner .mainForm .smallHalfWrapper .shBlocks.small {
    width: 30% !important;
  }
  .completeFormBlock .formInner .mainForm select.formFildInpt {
    background-position: 14px 50% !important;
    background-size: 12px !important;
  }
  .ErrorPopUpWrap .errorPopmain {
    width: 95%;
  }
  .dashboardWrap .dbContainer {
    width: 100%;
    float: left;
    padding: 0 15px;
  }
  .dashboardWrap .dbContainer .dbinner a.blueBttnStyle {
    padding: 15px 46px;
    font-size: 19px;
  }
  .completeRiskPageWrap .completeRiskPageContainer {
    width: 100%;
    float: left;
    padding: 0 12px;
  }
  .completeRiskPageWrap .completeRiskPageContainer .rpinner .noticeWrap {
    width: 100%;
  }
  .completeRiskPageWrap .completeRiskPageContainer .rpinner .sliderCompleteWrap .reactSlider .horizontal-slider .example-thumb {
    top: -1px;
    width: 43px;
    height: 43px;
  }
  .completeRiskPageWrap .completeRiskPageContainer .rpinner a.bluebttnStyle {
    padding: 15px 28px;
    font-size: 19px;
    margin: 41px 0 0 0;
  }
  .investmentTotalWrap .ipContainer {
    width: 100%;
    float: left;
    padding: 0 12px;
  }
  .investmentTotalWrap .ipContainer .ipInner {
    width: 100%;
  }
  .investmentTotalWrap .ipContainer .ipInner .pieChart {
    width: 230px;
    margin: 25px 0 25px 0;
  }
  .investmentTotalWrap .ipContainer .ipInner .pieChart .label_one {
    position: absolute;
    top: -16px;
    right: -38px;
  }
  .investmentTotalWrap .ipContainer .ipInner .pieChart .label_one::after {
    display: none;
  }
  .investmentTotalWrap .ipContainer .ipInner .pieChart .label_two {
    position: absolute;
    top: -11px;
    left: -47px;
  }
  .investmentTotalWrap .ipContainer .ipInner .pieChart .label_two::after {
    display: none;
  }
  .investmentTotalWrap .ipContainer .ipInner .pieChart .label_three {
    position: absolute;
    bottom: 18px;
    right: -51px;
  }
  .investmentTotalWrap .ipContainer .ipInner .pieChart .label_three::after {
    display: none;
  }
  .investmentTotalWrap .ipContainer .ipInner .pieChart .label_four {
    position: absolute;
    bottom: 2px;
    left: -52px;
  }
  .investmentTotalWrap .ipContainer .ipInner .pieChart .label_four::after {
    display: none;
  }
  .investmentTotalWrap .ipContainer .ipInner .ramgeSliderWrap {
    width: 100%;
  }
  .investmentTotalWrap .ipContainer .ipInner .ramgeSliderWrap .rSlid .arealabel p {
    font-size: 14px;
  }
  .investmentTotalWrap .ipContainer .ipInner .confirmationWrap {
    width: 100%;
  }
  .riskPopWrap .rPopMain {
    padding: 40px;
    width: 95%;
    min-height: 500px;
  }
  .riskPopWrap .rPopMain .rSlid .arealabel p {
    font-size: 13px;
  }
  .riskPopWrap .rPopMain .subBttn {
    padding: 15px 32px;
    font-size: 17px;
  }
}
@media screen and (min-width: 240px) and (max-width: 630px) {
  header {
    padding: 20px 19px;
  }
  header a.logoWrap {
    width: 120px;
    float: left;
  }
  header a.logoWrap img {
    width: 100%;
  }
  header .rightBttns {
    float: left;
    gap: 20px;
  }
  header .rightBttns a.btnStyle {
    font-size: 13px;
    padding: 6px 10px;
  }
  header .rightBttns a.linkStyle {
    font-size: 14px;
  }
  .dashboardWrap {
    margin: 20px 0 0 0;
  }
  .dashboardWrap .dbContainer .dbinner {
    width: 100%;
  }
  .dashboardWrap .dbContainer .dbinner picture img {
    width: 100px;
  }
  .dashboardWrap .dbContainer .dbinner h1 {
    font-size: 23px;
    margin: 0 0 20px 0;
  }
  .dashboardWrap .dbContainer .dbinner h1 small {
    font-size: 15px;
    line-height: 26px;
    margin: 12px 0 0 0;
  }
  .dashboardWrap .dbContainer .dbinner h3 {
    font-size: 18px;
  }
  .dashboardWrap .dbContainer .dbinner p {
    margin: 20px 0 0 0;
    font-size: 15px;
    line-height: 23px;
  }
  .dashboardWrap .dbContainer .dbinner a.blueBttnStyle {
    margin: 20px 0 0 0;
  }
  .completeRiskPageWrap {
    padding: 40px 0;
  }
  .investmentTotalWrap .ipContainer .ipInner {
    width: 100%;
    margin: 40px 0;
  }
  .investmentTotalWrap .ipContainer .ipInner h1 {
    font-size: 25px;
  }
  .investmentTotalWrap .ipContainer .ipInner .depositAmmount {
    margin: 30px 0 25px 0;
  }
  .investmentTotalWrap .ipContainer .ipInner .pieChart {
    width: 210px;
    margin: 30px 0 80px 0;
  }
  .investmentTotalWrap .ipContainer .ipInner .pieChart .label_three {
    position: absolute;
    bottom: -16px;
    right: -51px;
  }
  .investmentTotalWrap .ipContainer .ipInner .pieChart .label_three::after {
    display: none;
  }
  .investmentTotalWrap .ipContainer .ipInner .pieChart .label_four {
    position: absolute;
    bottom: -16px;
    left: -52px;
  }
  .investmentTotalWrap .ipContainer .ipInner .pieChart .label_four::after {
    display: none;
  }
  .investmentTotalWrap .ipContainer .ipInner .riskLevelCard {
    margin: 30px 0 0 0;
    padding: 30px;
  }
  .investmentTotalWrap .ipContainer .ipInner .confirmationWrap {
    padding: 0 5px;
  }
  .investmentTotalWrap .ipContainer .ipInner .confirmationWrap p {
    margin: 0 0 15px 0;
  }
  .investmentTotalWrap .ipContainer .ipInner .confirmationWrap .radioBox label span {
    width: 30px;
    height: 30px;
  }
  .investmentTotalWrap .ipContainer .ipInner .confirmationWrap .radioBox label span b.mark {
    width: 18px;
    height: 18px;
  }
  .completeFormBlock .formInner {
    position: relative;
  }
  .completeFormBlock .formInner .countBar {
    margin: 0 0 10px 0;
  }
  .completeFormBlock .formInner .mainForm {
    margin: 30px 0 0 0;
  }
  .completeFormBlock .formInner .mainForm .formFildDouble {
    width: 100%;
    display: block;
  }
  .completeFormBlock .formInner .mainForm .formFildDouble .formFildInpt {
    width: 100%;
    margin: 6px 0;
    height: 55px;
    font-size: 15px;
  }
  .completeFormBlock .formInner .mainForm .formFildSingles {
    margin: 0px 0 0 0;
  }
  .completeFormBlock .formInner .mainForm .formFildSingles .formFildInpt {
    width: 100%;
    margin: 6px 0;
    height: 55px;
    font-size: 15px;
  }
  .completeFormBlock .formInner .mainForm .completeRadioWrap {
    margin: 20px 0 10px 0;
  }
  .completeFormBlock .formInner .mainForm .completeRadioWrap p {
    font-size: 15px;
    font-weight: 600;
  }
  .completeFormBlock .formInner .mainForm .completeRadioWrap .radioBox {
    position: relative;
  }
  .completeFormBlock .formInner .mainForm .completeRadioWrap .radioBox label span {
    width: 30px;
    height: 30px;
  }
  .completeFormBlock .formInner .mainForm .completeRadioWrap .radioBox label span b.mark {
    width: 18px;
    height: 18px;
  }
  .completeFormBlock .formInner .mainForm .spouceInfo {
    margin: 20px 0 0 0;
  }
  .completeFormBlock .formInner .mainForm .spouceInfo h2 {
    margin: 10px 0 20px 0;
  }
  .completeFormBlock .formInner .mainForm .smallHalfWrapper {
    width: 100%;
  }
  .completeFormBlock .formInner .mainForm .smallHalfWrapper .shBlocks {
    margin: 3px 0;
  }
  .completeFormBlock .formInner .mainForm .smallHalfWrapper .shBlocks .formFildInpt {
    height: 55px;
    font-size: 15px;
    margin: 0 0;
  }
  .completeFormBlock .formInner .mainForm .smallHalfWrapper .shBlocks span {
    font-size: 16px;
  }
  .completeFormBlock .formInner .mainForm .confirmCheckBoxWrap {
    width: 100%;
  }
  .completeFormBlock .formInner .mainForm .confirmCheckBoxWrap label {
    gap: 10px;
  }
  .completeFormBlock .formInner .mainForm .confirmCheckBoxWrap label span {
    min-width: 30px;
    max-width: 30px;
    height: 30px;
  }
  .completeFormBlock .formInner .mainForm .confirmCheckBoxWrap label span svg {
    font-size: 17px;
  }
  .completeFormBlock .formInner .mainForm .confirmCheckBoxWrap label p {
    font-size: 15px;
    line-height: 20px;
  }
  .thumbnail {
    display: none;
  }
  .rSlid {
    margin: 30px 0 0 0 !important;
  }
  .completeRiskPageWrap .completeRiskPageContainer .rpinner .sliderCompleteWrap .reactSlider {
    margin: 30px 0 0 0;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1400px) {
  .riskPopWrap .rPopMain {
    width: 736px;
    min-height: 533px;
    padding: 42px;
  }
}/*# sourceMappingURL=responsive.css.map */