@media screen and (min-width: 240px) and (max-width:1000px){
    .completeFormBlock  {
        width: 100%;
        padding: 0 12px;
        .formInner {
            width: 100%;
            .countBar {
                width: 100%;
            }
            .mainForm {
                position: relative;
                .smallHalfWrapper {
                    margin: 6px 0;
                    .shBlocks {
                        width: 68% !important;
                        &.small {
                            width: 30% !important;
                        }
                    }
                }
                select.formFildInpt {
                    background-position: 14px 50% !important;
                    background-size: 12px !important;
                }
            }
        }
    }

    .ErrorPopUpWrap .errorPopmain {
        width: 95%;
    }

    .dashboardWrap .dbContainer {
        width: 100%;
        float: left;
        padding: 0 15px;
        .dbinner a.blueBttnStyle {
            padding: 15px 46px;
            font-size: 19px;
        }
    }

    .completeRiskPageWrap .completeRiskPageContainer {
        width: 100%;
        float: left;
        padding: 0 12px;
        .rpinner .noticeWrap {
            width: 100%;
        }
        .rpinner .sliderCompleteWrap .reactSlider .horizontal-slider .example-thumb {
            top: -1px;
            width: 43px;
            height: 43px;
        }
        .rpinner a.bluebttnStyle {
            padding: 15px 28px;
            font-size: 19px;
            margin: 41px 0 0 0;
        }
    }

    .investmentTotalWrap .ipContainer {
        width: 100%;
        float: left;
        padding: 0 12px;
        .ipInner {
            width: 100%;
            .pieChart {
                width: 230px;
                margin: 25px 0 25px 0;
                .label_one {
                    position: absolute;
                    top: -16px;
                    right: -38px;
                    &::after {
                        display: none;
                    }
                }
                .label_two {
                    position: absolute;
                    top: -11px;
                    left: -47px;
                    &::after {
                        display: none;
                    }
                }
                .label_three {
                    position: absolute;
                    bottom: 18px;
                    right: -51px;
                    &::after {
                        display: none;
                    }
                }
                .label_four {
                    position: absolute;
                    bottom: 2px;
                    left: -52px;
                    &::after {
                        display: none;
                    }
                }
            }
            .ramgeSliderWrap {
                width: 100%;
                .rSlid .arealabel p {
                    font-size: 14px;
                }
            }
            .confirmationWrap {
                width: 100%;
            }
        }
    }

    .riskPopWrap .rPopMain {
        padding: 40px;
        width: 95%;
        min-height: 500px;
        .rSlid .arealabel p {
            font-size: 13px;
        }
        .subBttn {
            padding: 15px 32px;
            font-size: 17px;
        }
    }    
}

@media screen and (min-width: 240px) and (max-width: 630px){
    header {
        padding: 20px 19px;
        a.logoWrap {
            width: 120px;
            float: left;
            img {
                width: 100%;
            }
        }
        .rightBttns {
            float: left;
            gap: 20px;
            a.btnStyle {
                font-size: 13px;
                padding: 6px 10px;
            }
            a.linkStyle {
                font-size: 14px;
            }
        }
    }
    .dashboardWrap {
        margin: 20px 0 0 0;
        .dbContainer .dbinner {
            width: 100%;
            picture img {
                width: 100px;
            }
            h1 {
                font-size: 23px;
                margin: 0 0 20px 0;
                small {
                    font-size: 15px;
                    line-height: 26px;
                    margin: 12px 0 0 0;
                }
            }
            h3 {
                font-size: 18px;
            }
            p {
                margin: 20px 0 0 0;
                font-size: 15px;
                line-height: 23px;
            }
            a.blueBttnStyle {
                margin: 20px 0 0 0;
            }
        }
    }
    .completeRiskPageWrap {
        padding: 40px 0;
    }
    .investmentTotalWrap .ipContainer .ipInner {
        width: 100%;
        margin: 40px 0;
        h1 {
            font-size: 25px;
        }
        .depositAmmount {
            margin: 30px 0 25px 0;
        }
        .pieChart {
            width: 210px;
            margin: 30px 0 80px 0;
            .label_three {
                position: absolute;
                bottom: -16px;
                right: -51px;
                &::after {
                    display: none;
                }
            }
            .label_four {
                position: absolute;
                bottom: -16px;
                left: -52px;
                &::after {
                    display: none;
                }
            }
        }
        .riskLevelCard {
            margin: 30px 0 0 0;
            padding: 30px;
        }
        .confirmationWrap {
            padding: 0 5px;
            p {
                margin: 0 0 15px 0;
            }
            .radioBox label span {
                width: 30px;
                height: 30px;
                b.mark {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }

    .completeFormBlock .formInner {
        position: relative;
        .countBar {
            margin: 0 0 10px 0;
        }
        .mainForm {
            margin: 30px 0 0 0;
            .formFildDouble {
                width: 100%;
                display: block;
                .formFildInpt {
                    width: 100%;
                    margin: 6px 0;
                    height: 55px;
                    font-size: 15px;
                }
            }
            .formFildSingles {
                margin: 0px 0 0 0;
                .formFildInpt {
                    width: 100%;
                    margin: 6px 0;
                    height: 55px;
                    font-size: 15px;
                }
            }
            .completeRadioWrap {
                margin: 20px 0 10px 0;
                p {
                    font-size: 15px;
                    font-weight: 600;
                }
                .radioBox {
                    position: relative;
                    label span {
                        width: 30px;
                        height: 30px;
                        b.mark {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
            .spouceInfo {
                margin: 20px 0 0 0;
                h2 {
                    margin: 10px 0 20px 0;
                }
            }
            .smallHalfWrapper {
                width: 100%;
                .shBlocks {
                    margin: 3px 0;
                    .formFildInpt {
                        height: 55px;
                        font-size: 15px;
                        margin: 0 0;
                    }
                    span {
                        font-size: 16px;
                    }
                }
            }
            .confirmCheckBoxWrap {
                width: 100%;
                label {
                    gap: 10px;
                    span {
                        min-width: 30px;
                        max-width: 30px;
                        height: 30px;
                        svg {
                            font-size: 17px;
                        }
                    }
                    p {
                        font-size: 15px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
    .thumbnail {
        display: none;
    }
    .rSlid {
        margin: 30px 0 0 0 !important;
    }
    .completeRiskPageWrap .completeRiskPageContainer .rpinner .sliderCompleteWrap .reactSlider {
        margin: 30px 0 0 0;
    }

}

@media screen and (min-width: 1000px) and (max-width: 1400px){
    .riskPopWrap .rPopMain {
        width: 736px;
        min-height: 533px;
        padding: 42px;
    }
}